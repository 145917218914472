import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Banner() {
  const { t: translate } = useTranslation();
  const [itemShown, setItemShown] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setItemShown((prevState) => !prevState); // Toggle the state
    }, 2500);
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url("img/hero/hero-1.jpg")`,
      }}
      className="h-[100vh] set-bg flex-1 flex flex-col items-center justify-center pt-[4rem] md:pt-[6rem] lg:pt-[0.5rem] p-[1rem] text-white"
    >
      {!itemShown ? (
        <div className="container flex flex-col items-center justify-center h-full">
          <div className="row">
            <div className="col-lg-6 flex flex-col items-center justify-center">
              <div className="hero__text">
                <h2
                  data-translate="int_2_txt"
                  className="uppercase font-semibold"
                >
                  {translate("int_txt")}
                  <strong>{translate("int_txt_2")}</strong>
                  {translate("int_txt_3")}
                </h2>
                <div className="flex flex-row justify-between mt-[4rem] md:justify-start md:gap-[2rem]">
                  <Link
                    to="#packages"
                    className="primary-btn"
                    data-translate="get_strt"
                  >
                    {translate("get_strt")}
                  </Link>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://speedtest.alemnet.net/"
                    className="primary-btn"
                    data-translate="speed_txt"
                    previewlistener="true"
                  >
                    {translate("speed_txt")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero__img">
                <img
                  src="./img/hero/alem web.png"
                  className="w-full h-full"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container flex flex-col items-center justify-center h-full">
          <div className="row">
            <div className="col-lg-6 flex flex-col items-center justify-center">
              <div className="hero__text">
                <h2
                  data-translate="int_txt"
                  className="uppercase font-semibold"
                >
                  {translate("int_2_txt")}
                </h2>
                <div className="flex flex-row justify-between mt-[4rem] md:justify-start md:gap-[2rem]">
                  <Link
                    to="#packages"
                    className="primary-btn"
                    data-translate="buy_txt"
                  >
                    {translate("buy_txt")}
                  </Link>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="http://speedtest.alemnet.net/"
                    className="primary-btn"
                    data-translate="speed_txt"
                    previewlistener="true"
                  >
                    {translate("speed_txt")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero__img">
                <img
                  src="./img/hero/alem web.png"
                  className="w-full h-full"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Banner;
