import React, { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import { useTranslation } from "react-i18next";
function Footer() {
  const { t: translate } = useTranslation();
  const dataCollectionRef = collection(db, "emailSubscriptionsNet");
  const [usermail, setUsermail] = useState("");
  const [subscribed, setSubscribed] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const fireSubmit = async (event) => {
    event.preventDefault();

    // Email validation
    if (!usermail) {
      alert("Please enter a valid email.");
      return;
    }

    // Send data to Firebase
    try {
      await addDoc(dataCollectionRef, {
        email_field: usermail,
      });

      setIsSubmitted(true);

      setTimeout(() => {
        window.location.reload();
      }, 3500);
    } catch (error) {
      // setSuccessMessage("Error, try again or reload !");
      console.error("Error submitting form:", error);
    }
  };
  const currentYear = new Date().getFullYear();
  return (
    <footer id="footer_footer" className="footer-section">
      <div className="footer__top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="footer__top-call">
                <h5 data-translate="nd_hlp">{translate("nd_hlp")}</h5>
                <h2>
                  <a href="tel:+90 5428772009">+90 5428772009</a>
                </h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="footer__top-auth">
                <h5 data-translate="jn_nl">{translate("jn_nl")}</h5>
                <form
                  method="post"
                  className="subscribe-form"
                  id="wf-form-Newsletter-Form"
                  name="wf-form-Newsletter-Form"
                >
                  <input
                    type="email"
                    placeholder={translate("ent_email")}
                    aria-label="Email address"
                    className="subscribe-input"
                    name="email_a"
                    value={usermail}
                    onChange={(event) => {
                      setUsermail(event.target.value);
                    }}
                    required
                  />
                  <input
                    type="text"
                    placeholder={"Email address"}
                    aria-label="Email address"
                    className="hide"
                    value={subscribed}
                    name="subscribe"
                    id="subscribe"
                    onChange={(event) => {
                      setSubscribed(event.target.checked);
                    }}
                  />
                  <div className="subscribe-btn-div">
                    <input
                      type="submit"
                      aria-label="Submit"
                      className="subscribe-button"
                      id="form-submit"
                      name="form-submit"
                      value={translate("scrb")}
                      onClick={fireSubmit}
                    />
                  </div>
                </form>
                {isSubmitted && (
                  <div className={`form-message-success w-form-done w-sub `}>
                    <div data-translate="frm_4">{translate("frm_4")}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer__text set-bg"
        data-setbg="img/footer-bg.png"
        style={{ backgroundImage: `url("img/footer-bg.png")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer__text-about">
                <div className="footer__logo">
                  <Link to="/" previewlistener="true">
                    <img src="img/logo.png" alt="" />
                  </Link>
                </div>
                <p data-translate="ft_txt">{translate("ft_txt")}</p>
                <p>
                  {translate("opn")} <br />
                  {translate("tme")}
                </p>
                <div className="footer__social">
                  <a
                    href="https://www.facebook.com/alemnettechnoshop"
                    previewlistener="true"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/alemdar_network"
                    previewlistener="true"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer__text-widget">
                <h5 data-translate="cmpny">{translate("cmpny")}</h5>
                <ul>
                  <li>
                    <Link
                      data-translate="cmp_fq"
                      to="/faq"
                      previewlistener="true"
                    >
                      {translate("cmp_fq")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" data-translate="cst_agr">
                      {translate("cst_agr")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      data-translate="bnk_a"
                      to="/bank_accounts"
                      previewlistener="true"
                    >
                      {translate("bnk_a")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer__text-widget">
                <h5 data-translate="lnks">{translate("lnks")}</h5>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="http://speedtest.alemnet.net/"
                      data-translate="speed_txt"
                      previewlistener="true"
                    >
                      {translate("speed_txt")}
                    </a>
                  </li>
                  <li>
                    <Link
                      data-translate="price_txt"
                      to="/pricing"
                      previewlistener="true"
                    >
                      {translate("price_txt")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/" data-translate="srvc">
                      {translate("srvc")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer__text-widget">
                <h5 data-translate="cntct">{translate("cntct")}</h5>
                <ul className="footer__widget-info">
                  <li>
                    <span className="fa fa-map-marker"></span> MEHMET AKİF
                    CADDESİ NO:63 <br />
                    DEREBOYU LEFKOŞA
                  </li>
                  <li>
                    <a href="tel:+90 5428772009">
                      <span className="fa fa-mobile"></span>+90 5428772009
                    </a>
                  </li>
                  <li>
                    <a href="mailto:alemdar_488@hotmail.com">
                      <span className="fa fa-headphones"></span>
                      alemdar_488@hotmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer__text-copyright">
            <p>
              Copyright © {currentYear} All rights reserved | This website is
              made with <i className="fa fa-heart" aria-hidden="true"></i> by{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https:/teddy-webdev.click"
                previewlistener="true"
              >
                teddy-webdev
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
